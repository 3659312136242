<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="editPatientForm"
        :key="index"
        v-model="formValid"
        :disabled="!baseDataLoaded"
      >
        <text-field
          v-model="patient.first_name"
          label="First Name"
          counter="50"
          required
          @blur="patient.first_name = $custom.toNameCase(patient.first_name)"
        ></text-field>
        <v-expand-transition>
          <text-field
            v-if="showFullProfile"
            v-model="patient.middle_name"
            label="Middle Name"
            counter="50"
            @blur="patient.middle_name = $custom.toNameCase(patient.middle_name)"
          ></text-field>
        </v-expand-transition>
        <text-field
          v-model="patient.last_name"
          label="Last Name"
          counter="50"
          required
          @blur="patient.last_name = $custom.toNameCase(patient.last_name)"
        ></text-field>
        <v-expand-transition>
          <text-field
            v-if="showFullProfile"
            v-model="patient.suffix"
            label="Suffix"
            counter="20"
            @blur="patient.suffix = $custom.toUpperCase(patient.suffix)"
          ></text-field>
        </v-expand-transition>
        <date-input
          v-model="patient.dob_us"
          label="Date of Birth"
          rules="dobRequireAdult"
          show-age
          required
        ></date-input>
        <v-expand-transition>
          <radio-buttons
            v-if="showFullProfile"
            v-model="patient.gender"
            :items="sex"
            label="Gender"
            required
          ></radio-buttons>
        </v-expand-transition>

        <!--TXP-322: Hide this field if the user didn't fill in both names because it was causing users to overlook existing patients. -->
        <text-field
          v-model="patient.ssn_last_4"
          v-if="patient.first_name && patient.last_name"
          label="Last 4 of SSN"
          :rules="SSNRules"
          :required="isSSNRequired"
          mask="####"
          placeholder="####"
          counter="4"
          numeric
        ></text-field>
        <v-expand-transition>
          <select-box
            v-if="showFullProfile"
            v-model="patient.place_of_service_id"
            :items="facilities"
            label="Facility"
            not-attached
            required
          ></select-box>
        </v-expand-transition>
      </v-form>
    </v-card-text>

    <v-expand-transition>
      <div v-show="displayResults">
        <v-card-title class="title">
          {{
            encounter
              ? 'Select Patient to Create Encounter'
              : (
                quickEntry
                  ? 'Select Patient to Create Quick Entry'
                  : 'Matching Patient Profiles'
              )
          }}
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="computedPatients"
          item-key="id"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          class="has-pagination"
          hide-default-footer
          @page-count="pagination.pageCount = $event"
          @click:row="viewPatient"
        >
          <!-- dob -->
          <template #item.dob="{ item }">
            {{ $date(item.dob).format('MM/DD/YYYY') }}
          </template>

          <!-- facility -->
          <template #item.place_of_service_id="{ item }">
            {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
          </template>

          <!-- actions -->
          <template #item.actions="{item}">
            <v-menu
              transition="slide-y-transition"
              offset-y
              left
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="viewPatient(item)">
                  <icon-value :icon="icons.mdiAccountEye">
                    View Patient Profile
                  </icon-value>
                </v-list-item>

                <v-list-item @click="editPatient(item)">
                  <icon-value :icon="icons.mdiAccountEdit">
                    Edit Patient Profile
                  </icon-value>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  :disabled="item.is_deceased"
                  @click="createEncounter(item)"
                >
                  <icon-value
                    :disabled="item.is_deceased"
                    :icon="icons.mdiAccountMultiplePlus"
                  >
                    Create Encounter
                  </icon-value>
                </v-list-item>

                <v-list-item
                  :disabled="item.is_deceased"
                  @click="createQuickEntry(item)"
                >
                  <icon-value
                    :disabled="item.is_deceased"
                    :icon="icons.mdiClockPlus"
                  >
                    Create Quick Entry
                  </icon-value>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <!-- pagination -->
        <pagination
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :page-count="pagination.pageCount"
        ></pagination>
      </div>
    </v-expand-transition>

    <v-card-actions>
      <!-- cancel -->
      <btn
        label="Cancel"
        color="secondary"
        :icon="icons.mdiCancel"
        @click="cancel"
      ></btn>

      <v-spacer></v-spacer>

      <!-- search patient profiles -->
      <btn
        v-if="!quickEntry"
        :disabled="!formValid"
        :icon="icons.mdiAccountPlus"
        label="Create Patient"
        @click="searchPatientProfiles"
      ></btn>

      <!-- create patient and quick entry -->
      <btn
        v-else
        :disabled="!formValid"
        :icon="icons.mdiAccountPlus"
        label="Create Patient & Quick Entry"
        @click="createPatientQuickEntry"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SyncEncounters from '@/mixins/SyncEncounters';
import {
  mdiAccountEdit,
  mdiAccountEye,
  mdiAccountMultiplePlus,
  mdiAccountPlus, mdiCancel,
  mdiClockPlus,
  mdiClose,
  mdiDotsVertical,
} from '@mdi/js';
import { mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

const initialState = {
  by: 'last_name',
  desc: false,
}

export default {
  mixins: [SyncEncounters],
  props: {
    title: {
      type: String,
      default: '',
    },
    encounter: {
      type: Boolean,
      default: false,
    },
    quickEntry: {
      type: Boolean,
      default: false,
    },
    facility: {
      type: Number,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: 0,
      formValid: false,
      patient: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        dob: '',
        dob_us: '',
        gender: '',
        ssn_last_4: '',
        place_of_service_id: this.facility,
        date_deceased: '',
        cause_of_death: '',
        is_deceased: false,
        updated: false,
        wounds: [],
        all_attachments: [],
      },
      icons: {
        mdiAccountPlus, mdiCancel, mdiAccountEye, mdiAccountEdit, mdiAccountMultiplePlus, mdiClockPlus, mdiDotsVertical, mdiClose,
      },
      sort: { ...initialState },
      headers: [
        {
          text: 'First',
          value: 'first_name',
          filter: value => !this.patient.first_name || value.toLowerCase().indexOf(this.patient.first_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'Last',
          value: 'last_name',
          filter: value => !this.patient.last_name || value.toLowerCase().indexOf(this.patient.last_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'DOB',
          value: 'dob',
          filter: value => !this.patient.dob || value === this.patient.dob,
        },
        {
          text: 'SSN',
          value: 'ssn_last_4',
          align: 'hide',
          filter: value => !this.patient.ssn_last_4 || value === this.patient.ssn_last_4,
        },
        {
          text: 'Gender',
          value: 'gender',
          align: 'small',
        },
        {
          text: 'Facility',
          value: 'place_of_service_id',
          align: 'facility',
        },
        {
          text: '', value: 'actions', align: 'xsmall', sortable: false,
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
    }
  },
  computed: {
    ...mapGetters('encounters', ['baseDataLoaded', 'genders']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('baseData', ['sex']),
    ...mapState('patients', { patients: 'items' }),
    ...mapFields(['online']),
    computedPatients() {
      return this.quickEntry || this.encounter
        ? this.patients.filter(x => !x.is_deceased)
        : this.patients
    },
    isSSNRequired() {
      return !!this.patient.ssn_last_4 || (this.quickEntry && this.showFullProfile)
    },
    SSNRules() {
      return this.isSSNRequired ? 'exactLength(4)' : ''
    },
    displayResults() {
      return (this.pagination.pageCount > 0
        && (
          this.patient.first_name?.length >= 1
          || this.patient.last_name?.length >= 1
          || this.patient.dob?.length === 10
          || this.patient.ssn_last_4?.length === 4
        )
      )
    },
    showFullProfile() {
      return (this.quickEntry
          // We should show all fields even if there are possible matches found,
          // or else, users will be able to add patients with missing information
          // such as gender and facility.
          // && this.pagination.pageCount === 0

          && this.patient.first_name?.length >= 1
          && this.patient.last_name?.length >= 1
          && this.patient.dob?.length === 10
      )
    },
  },
  watch: {
    'patient.dob_us': {
      handler() {
        this.patient.dob = this.patient.dob_us?.length === 10 ? this.$date(this.patient.dob_us).format('YYYY-MM-DD') : null
      },
    },
    modalState() {
      this.index += 1
    },
  },
  beforeMount() {
    // Remove action menu column for quick entry view
    if (this.quickEntry) {
      const index = this.headers.findIndex(x => x.value === 'actions')
      if (index !== -1) delete this.headers[index]
    }
  },
  mounted() {

  },
  methods: {
    viewPatient(item) {
      if (this.encounter) {
        this.createEncounter(item)
      } else if (this.quickEntry) {
        this.$emit('update:modal-state', false)
        this.$emit('selected', item)
      } else {
        this.$router.push({ name: 'view-patient', query: { id: item.id } })
      }
    },
    editPatient(item) {
      this.$router.push({ name: 'edit-patient', query: { id: item.id } })
    },
    createEncounter(item) {
      this.$router.push({ name: 'create-encounter', query: { id: item.id } })
    },
    createQuickEntry(item) {
      this.$router.push({ name: 'create-encounter', query: { id: item.id, quickEntry: true } })
    },
    cancel() {
      if (this.quickEntry) {
        this.$emit('update:modal-state', false)
      } else if (this.formValid) {
        this.$root.confirm({
          title: 'Cancel?',
          body: 'Are you sure you wish to cancel?',
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('route/back')
          }
        })
      } else {
        this.$store.dispatch('route/back')
      }
    },
    searchPatientProfiles() {
      // Trim patient name fields
      this.patient.first_name = this.patient.first_name.trim()
      this.patient.last_name = this.patient.last_name.trim()

      const duplicates = this.$store.getters['patients/getDuplicates'](this.patient)

      if (duplicates.length === 1) {
        // Found exactly one matching patient, view patient profile
        this.$router.push({ name: 'view-patient', query: { id: duplicates[0].id } })
      } else if (duplicates.length > 1) {
        // Found multiple matching patients, view results
        this.$root.confirm({
          title: 'Multiple Patients Found',
          body: 'Multiple matching patient profiles found, showing matching results.',
          cancel: false,
        }).then(() => {
          this.$router.push({ name: 'list-patients', query: { ...this.patient } })
        })
      } else {
        // No match found, create patient profile?
        this.$root.confirm({
          title: 'Create Patient Profile?',
          body: 'Patient not found, do you wish to create a patient profile?',
        }).then(result => {
          if (result) {
            this.$router.push({ name: 'add-patient', params: { patient: this.patient } })
          }
        })
      }
    },
    createPatientQuickEntry() {
      // Trim patient name fields
      this.patient.first_name = this.patient.first_name.trim()
      this.patient.middle_name = this.patient.middle_name.trim()
      this.patient.middle_initial = this.patient.middle_name ? this.patient.middle_name.charAt(0) : '';
      this.patient.last_name = this.patient.last_name.trim()
      this.patient.suffix = this.patient.suffix.trim()

      const duplicates = this.$store.getters['patients/getDuplicates'](this.patient)

      if (duplicates.length === 0) {
        // No match found, create patient profile and quick entry
        this.$store.commit('patients/addPatient', this.patient)
        this.patient.id = this.$store.getters['patients/getLastId']
        if (this.online) {
          this.$store.dispatch('encounters/syncingModal', true)
          this.$store.dispatch('auth/refresh')
          console.log(this.patient)
          this.$store.dispatch('patients/syncPatient', this.patient)
            .then(async response => {
              if (response.responseData[0].status === 'Error' && response.responseData[0].message.toLowerCase().includes('duplicate')) {
                // Get facility name of local patient
                const localFacility = this.$store.getters['facilities/getById'](this.patient.place_of_service_id)?.title

                // Get facility name of existing patient
                const duplicatePatients = response.responseData[0].dupPatient

                await this.duplicatePatientConfirmation(
                  { ...this.patient, facility: localFacility },
                  duplicatePatients,
                ).then(duplicateResponse => {
                  if (duplicateResponse.id) {
                    const duplicatePatientOriginalValue = duplicateResponse.originalValue
                    const existingId = this.resolveDuplicatePatientRecord(duplicatePatientOriginalValue)

                    if (existingId !== false) {
                      this.$store.commit('patients/deletePatient', this.patient.id)
                      let duplicatePatient = this.$store.getters['patients/getById'](existingId)
                      this.patient = {...duplicatePatient}
                    }
                  }

                })
              }
            })
            .finally(() => {
              this.resolveQuickEntry();
              this.$store.dispatch('encounters/syncingModal', false)
            })
        } else {
          this.resolveQuickEntry();
        }

      } else {
        // This should never happen, but just in case, return error
        this.$store.dispatch('notify', { value: 'Error creating patient, duplicate patient already exists.', color: 'error' })
      }
    },

    resolveQuickEntry() {
      this.$emit('selected', this.patient)
      this.$emit('update:modal-state', false)
    },
  },
}
</script>
